






<p-tieredMenu #tieredmenuitem [model]="tierdmenuitemlist" [popup]="true" appendTo="body"></p-tieredMenu>
<p-tieredMenu #tieredmenu [model]="tierdmenulist" [popup]="true" appendTo="body"></p-tieredMenu>

<ng-container *ngFor="let f of fields;">

    <div class="knbn-container" class="{{(f.class) ? f.class : ''}}" >
        <div class="knbn-inner">
            <div [style]="headerStyle(f)" class="knbn-label {{(f.labelClass) ? f.labelClass : 'default'}}">{{f.Label}} 
                <span class="kanban-count" *ngIf="listCount(f.value)>0">{{listCount(f.value)}}</span>
                <button #btn type="button" class="knbn-head-btn"  pButton icon="pi pi-ellipsis-v" (click)="openTieredMenu($event, f)"></button>
            </div>


            
            <ng-container *ngIf="loading">
                <div class="card-loading"></div>
                <div class="card-loading"></div>
                <div class="card-loading"></div>
                <div class="card-loading"></div>
            </ng-container>

            <div 
                *ngIf="!loading"
                style="    height: calc(100vh - {{height_cut}}px);"
                class="knbn-drop-zone" 
                dndDropzone 
                (dndDragover)="onDragover($event)"
                (dndDrop)="onDrop($event, f.value)">


                <div
                  class="knbn-drop-placeholder"
                  dndPlaceholderRef>
                </div>




    <ng-container *ngFor="let d of listItems(f.value);">
                <div
                    dndDragImageRef
                    class="knbn-itm"
                    [ngClass]="{'date_due': isDue(f.card_fields?.due, d[f.card_fields.date])}"
                    [dndDraggable]="d"
                    dndEffectAllowed="all"
                    [dndDisableIf]="draggable.disable"
                    (dndStart)="onDragStart($event, d)"
                    (dndCopied)="onDraggableCopied($event)"
                    (dndLinked)="onDraggableLinked($event)"
                    (dndMoved)="onDraggableMoved($event)"
                    (dndCanceled)="onDragCanceled($event)"
                    (dblclick)="viewItem(d)"
                    (dndEnd)="onDragEnd($event)">

                    <!--if [dndHandle] is used inside dndDraggable drag can only start from the handle-->
                    <!-- <p-button dndHandle icon="pi pi-arrows-alt" size="small"></p-button> -->
                  

                    <!-- draggable ({{draggable.effectAllowed}})  -->
                    <!-- <span [hidden]="!draggable.disable">DISABLED</span> -->

                    <!--optionally select a child element as drag image-->
                    <!-- <div  class="card-item"> -->
<ng-container *ngIf="!f.innerHtml">
    <label>
        {{d[f.card_fields.label]}}
    </label>
    <pre pTooltip="{{d[f.card_fields.description]}}" tooltipPosition="top">
        {{d[f.card_fields.description]}}
    </pre>
    <div class="pull-left w-100">
        <span class="pull-left w-50"><i class="fa fa-calendar"></i> {{ commonservice.convertDT(d[f.card_fields.date],'MM/DD/Y') }}</span>
        <span class="pull-left w-50" *ngIf="f.card_fields.user && d[f.card_fields.user]"><i class="fa fa-user-circle-o"></i> {{commonservice.userName(d[f.card_fields.user])}}</span>
    </div>
    {{f.buttons}}
</ng-container>

<ng-container *ngIf="f.innerHtml">
    <div pTooltip="{{d[f.card_fields.description]}}" tooltipPosition="top" [innerHTML]="d[f.innerHtml]"></div>
</ng-container>


                    <button #btn type="button" class="knbn-item-btn"  pButton icon="pi pi-ellipsis-v" (click)="openTieredMenuItem($event, d, f)"></button>
                    <!-- </div> -->
</div>

</ng-container>


              



            

            </div>
        </div>
    </div>



</ng-container>